import React, { useEffect, useMemo, useState } from 'react';
import { Button, Flex, Modal, Table, Input  } from 'antd';
import { getSketchList, postSketch } from '../axios/sketch';
import { NavLink } from 'react-router-dom';

import type { TableColumnsType } from 'antd';

interface Skecth {
    createdAt: number;
    name: string;
    id: string;
}

const columns: TableColumnsType<Skecth> = [
    {
        key: 'name',
        title: '剧名',
        dataIndex: 'name',
    },
    {
        key: 'createdAt',
        title: '创建时间',
        dataIndex: 'createdAt',
        render: (val) => {
            return val
        }
    },
    {
        key: 'options',
        title: '操作',
        dataIndex: 'options',
        render: (_, val) => {
            return (<NavLink to={`/sketch/${val.id}`} key={val.id}>查看</NavLink>)
        }
    },
];

export default function SketchList() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [sketchName, setSketchName] = useState("");
    const [sketchList, setSketchList] = useState([]);
    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
        postSketch({ name: sketchName }).then(({data}) => {
            getSketchListData()
        })
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    function getSketchListData() {
        getSketchList({}).then(({data}) => {
            if(data.status === "error") {
            } else {
                setSketchList(data.result);
            }
        })
    }
    useEffect(() => {
        getSketchListData();
    }, [])
    return (
        <div>
            <Flex gap="middle" vertical>
                <Flex gap="middle">
                    <Button onClick={showModal}>添加新剧</Button>
                    <Button>删除</Button>
                </Flex>
                <Table columns={columns} dataSource={sketchList} />
            </Flex>
            <Modal title="新的短剧" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <Input onChange={(e) => {setSketchName(e.target.value)} }/>
            </Modal>
        </div>
    )
}