import { get, post } from "./base.js";

export function getProduct(params) {
    return get("/product", params);
}

export function getProductList(params) {
    return get("/product/list", params);
}

export function postProduct(body) {
    return post("/product", body);
}

export function postProductTask(body) {
    return post("/task/product", body);
}