import { Button, Checkbox, Descriptions, Divider, Flex, Modal, Radio, Table, TableColumnsType, Tag, Spin, Input, Slider } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { getProductList, getProject, postProductTask, postProjectTask } from '../axios/index';
import VideoOverlay from '../components/VideoOverlay';
import { Rect, VideoSize } from '../interface/index';
import { getTime } from '../utils/time';
import { InputNumber } from 'antd';
const taskType2CN: {
    [key: string]: string
} = {
    '0': "简单变换",
    '1': "解说词转写",
    '2': "解说词生成"
}

const transformKind2CN: {
    [key: string]: string
} = {
    'speed': "变速",
    'flip': "翻转",
    "color": "颜色",
    "transition": "转场",
    "superscript": "角标",
    "name": "剧名",
    "subtitle": "字幕",
    "crop": "裁剪"
}

const taskStatus2CN: {
    [key: string]: string
} = {
    "notstart": "未开始",
    "processing": "进行中",
    "finished": "已完成"
}

const projectType2Cn: {
    [key: string]: string
} = {
    "0": "纯剧情",
    "1": "有解说"
}

interface Project {
    id: string,
    name: string,
    type: string,
    task: {
        cleanVideo: {
            status: string,
        },
        subtitleExtraction: {
            status: string,
        },
        basicInfo: {
            status: string,
        }
    }
    audioUrl: string,
    cleanVideoUrl: string,
    videoUrl: string,
    sketch: {
        name: string
    }
}

interface Product {
    id: string,
    type: string,
    videoUrl: string,
    rewriteRoles: string[],
    transformKinds: string[],
    task: {
        video: {
            status: string,
        },
        subtitles: {
            status: string,
        }
    }
}

export default function ProjectDetail() {
    const params = useParams();
    const navigate = useNavigate();
    const projectId = useMemo(() => params.id, [params]);
    const [projectInfo, setProjectInfo] = useState<Project>();
    const [productList, setProductList] = useState<Product[]>([]);
    const [productTaskType, setProductTaskType] = useState(0);
    const [transformKinds, setTransformKinds] = useState<string[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSubtitleModalOpen, setIsSubtitleModalOpen] = useState(false);
    const [isCleanVideoModalOpen, setIsCleanVideoModalOpen] = useState(false);
    const [rects, setRects] = useState<Rect[]>([]);
    const [cleanTaskType, setCleanTaskType] = useState(0);
    const [productName, setProductName] = useState("");
    const [videoSize, setVideoSize] = useState<VideoSize>({ width: 0, height: 0 });
    const [useCleanVideo, setUseCleanVideo] = useState(false);
    const [flipRate, setFlipRate] = useState(0);
    const [taskNum, setTaskNum] = useState(1);
    const [taskStatus, setTaskStatus] = useState({
        basicInfo: false,
        cleanVideo: false,
        subtitleExtraction: false,
    })

    const columns: TableColumnsType<Product> = useMemo(() =>[
        {
            key: 'id',
            title: 'ID',
            dataIndex: 'id',
        },
        {
            key: 'type',
            title: '类型',
            dataIndex: 'type',
            render: (val: string) => {
                return taskType2CN[val]
            }
        },
        {
            key: 'transformKinds',
            title: '变换',
            dataIndex: 'transformKinds',
            render: (val: string[]) => {
                return val.map((kind) => <Tag color='#2db7f5'>{transformKind2CN[kind]}</Tag>)
            }
        },
        {
            key: 'createdAt',
            title: '创建时间',
            dataIndex: 'createdAt',
            render: (time) => {
                return getTime(time)
            }
        },
        {
            key: 'task',
            title: '状态',
            dataIndex: 'task',
            render: (task, record) => {
                return <div>
                    {
                        record.type !== "0" && (
                            <div>
                                台词任务：
                                {
                                    (!task.subtitles?.status || task.subtitles?.status === "notstart") && (<span style={{
                                        background: "yellow"
                                    }}>未开始</span>)
                                }
                                {
                                    task.subtitles?.status === "processing" && (<span style={{
                                        background: "#afafff"
                                    }}>进行中</span>)
                                }
                                {
                                    task.subtitles?.status === "finished" && (<span style={{
                                        background: "#b3ffb3"
                                    }}>已完成</span>)
                                }
                            </div>
                        )
                    }
    
                    <div>
                        视频任务：
                        {
                            (!task.video?.status || task.video?.status === "notstart") && (<span style={{
                                background: "yellow"
                            }}>未开始</span>)
                        }
                        {
                            task.video?.status === "processing" && (<span style={{
                                background: "#afafff"
                            }}>进行中</span>)
                        }
                        {
                            task.video?.status === "finished" && (<span style={{
                                background: "#b3ffb3"
                            }}>已完成</span>)
                        }
                    </div>
                </div>
            }
        },
        {
            key: 'operator',
            title: '操作',
            dataIndex: 'operator',
            render: (val, record, index) => {
                return <NavLink to={`/product/task`} state={{ product: record, project: projectInfo }}>
                    查看
                </NavLink>
            }
        }
    ], [projectInfo]);
    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
        postProductTask({
            type: productTaskType,
            taskNum,
            transformKinds,
            transformConfigs: {
                rects: rects.map((rect) => ({
                    x: Number((rect.left / videoSize.width).toFixed(2)),
                    y: Number((rect.top / videoSize.height).toFixed(2)),
                    width: Number((rect.width / videoSize.width).toFixed(2)),
                    height: Number((rect.height / videoSize.height).toFixed(2)),
                    type: rect.type
                })),
                flipRate,
                useCleanVideo,
            },
            projectId,
            rewriteRoles: ["1"],
            productName,
        }).then(({ data }) => {
            getProductList({ projectId }).then(({ data }) => {
                setProductList(data.result);
            })
        });
        setRects([]);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setRects([]);
    };

    const handleSubtitleExtraction = () => {
        setIsSubtitleModalOpen(true);
    };

    const handleSubtitleOk = () => {
        setIsSubtitleModalOpen(false);
        postProjectTask({ type: 0, projectId, rects: rects.map((rect) => ({
            x: Number((rect.left / videoSize.width).toFixed(2)),
            y: Number((rect.top / videoSize.height).toFixed(2)),
            w: Number((rect.width / videoSize.width).toFixed(2)),
            h: Number((rect.height / videoSize.height).toFixed(2))
        })) }).then(({ data }) => {
            setRects([]);
            console.log(data);
        })
    };

    const handleSubtitleCancel = () => {
        setRects([]);
        setIsSubtitleModalOpen(false);
    }

    const handleCleanVideoOk = () => {
        setIsCleanVideoModalOpen(false);
        postProjectTask({ type: 1, cleanTaskType, projectId, rects: rects.map((rect) => ({
            x: Number((rect.left / videoSize.width).toFixed(2)),
            y: Number((rect.top / videoSize.height).toFixed(2)),
            w: Number((rect.width / videoSize.width).toFixed(2)),
            h: Number((rect.height / videoSize.height).toFixed(2))
        }))}).then(({ data }) => {
            setRects([]);
            console.log(data);
        })
    }
    const handleCleanVideoCancel = () => {
        setRects([]);
        setIsCleanVideoModalOpen(false);
    }

    const handleRectsChange = (rects: Rect[]) => {
        setRects([...rects]);
    }

    const handleProductTaskTypeChange = (type: number) => {
        setProductTaskType(type);
        if(type !== 0) {
            setUseCleanVideo(true)
        }
    }

    const handleTransformKindsChange = (kinds: string[]) => {
        setTransformKinds(kinds);
        if((kinds.includes('superscript') || kinds.includes('name') || kinds.includes('flip') || kinds.includes('subtitle')) && taskStatus.cleanVideo) {
            setUseCleanVideo(true);
        }
    }

    const handleuseCleanVideoChange = (val: boolean) => {
        setUseCleanVideo(val);
        if(val === false) {
            setProductTaskType(0);
        }
    }
    const updateProjectdata = useCallback(
        (updateName: boolean) => {
            getProject({ projectId }).then(({ data }) => {
                setProjectInfo(data.result);
                if(updateName) {
                    setProductName(data.result?.sketch?.name);
                }
                setTaskStatus({
                    basicInfo: data.result?.task.basicInfo.status === 'finished',
                    cleanVideo: data.result?.task.cleanVideo.status === 'finished',
                    subtitleExtraction: data.result?.task.subtitleExtraction.status === 'finished',
                })
            });
            getProductList({ projectId }).then(({ data }) => {
                setProductList(data.result);
            })
        },
        [projectId]
    ) 
    useEffect(() => {
        updateProjectdata(true);
    }, [projectId, updateProjectdata]);

    useEffect(() => {
        const timer = setInterval(() => {
            updateProjectdata(false)
        }, 10000);

        return () => {
            clearInterval(timer);
        };
    }, [projectId, updateProjectdata]);
    return (
        <div>
            <Descriptions title={<Flex justify={"space-between"}>
                <div>项目基础信息</div>
                <Button onClick={() => navigate('/project/task', { state: { project: projectInfo } })}>查看</Button> </Flex>}>
                <Descriptions.Item label="项目名">{projectInfo?.name}</Descriptions.Item>
                <Descriptions.Item label="所属剧名">{projectInfo?.sketch.name}</Descriptions.Item>
                <Descriptions.Item label="类型">{projectType2Cn[projectInfo?.type || "0"]}</Descriptions.Item>
                <Descriptions.Item label="视频">
                    {
                        projectInfo?.task.basicInfo?.status === "processing" && (<div>加载中<Spin /></div>)
                    }
                    {
                        projectInfo?.task.basicInfo?.status === "finished" && (<div>完成初始化</div>)
                    }
                </Descriptions.Item>
                <Descriptions.Item label="无水印视频">
                    {
                        projectInfo?.task.cleanVideo.status === "notstart" && (<div onClick={() => setIsCleanVideoModalOpen(true)}>未开始</div>)
                    }
                    {
                        projectInfo?.task.cleanVideo.status === "processing" && (<div onClick={() => setIsCleanVideoModalOpen(true)}>进行中<Spin /></div>)
                    }
                    {
                        projectInfo?.task.cleanVideo.status === "finished" && (<div onClick={() => setIsCleanVideoModalOpen(true)}>查看</div>)
                    }
                </Descriptions.Item>
                <Descriptions.Item label="台词">
                    {
                        projectInfo?.task.subtitleExtraction.status === "notstart" && (<div onClick={handleSubtitleExtraction}>未开始</div>)
                    }
                    {
                        projectInfo?.task.subtitleExtraction.status === "processing" && (<div onClick={handleSubtitleExtraction}>进行中 <Spin /></div>)
                    }
                    {
                        projectInfo?.task.subtitleExtraction.status === "finished" && (
                            <Flex gap={5}>
                                <div onClick={() => navigate('/project/task', { state: { project: projectInfo } })}>查看</div>
                                <div onClick={handleSubtitleExtraction}>重新识别</div>
                            </Flex>
                    )
                    }
                </Descriptions.Item>
            </Descriptions>
            <Divider />
            <div>
                <Flex justify={"space-between"} align={"center"}>
                    <div style={{
                        fontWeight: "bold",
                        fontSize: "16px"
                    }}>作品列表</div>
                    <Button onClick={showModal}>新作品</Button>
                </Flex>
                <Table columns={columns} dataSource={productList} />
            </div>
            <Modal title="新作品" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} width={720}>
                <Flex justify={'space-between'}>
                    <Flex vertical={true} gap={10}>
                        <Radio.Group onChange={(e) => handleProductTaskTypeChange(e.target.value)} value={productTaskType}>
                            <Radio value={0}>智能一键去重</Radio>
                            <Radio value={1} disabled={ !(taskStatus.cleanVideo && taskStatus.subtitleExtraction) }>AI解说词转写</Radio>
                            {/* <Radio value={2} >AI解说词生成</Radio> */}
                        </Radio.Group>
                        <Checkbox.Group onChange={(e) => { handleTransformKindsChange(e) }}>
                            <Flex vertical={true} gap={5}>
                                <div>
                                    <Checkbox value="color">随机滤镜</Checkbox>
                                </div>
                                <div>
                                    <Checkbox value="speed" disabled={!taskStatus.basicInfo}>智能变速</Checkbox>
                                </div>
                                <div>
                                    <Checkbox value="transition" disabled={!taskStatus.basicInfo}>分镜转场</Checkbox>
                                </div>
                                <div>
                                    <Checkbox value="crop">视频裁剪</Checkbox>
                                    {
                                        transformKinds.includes('crop') && (
                                            <Button onClick={() => {
                                                if(rects.findIndex((val) => val.type === 'crop') === -1) {
                                                    setRects([
                                                        ...rects,
                                                        {
                                                            id: Date.now(),
                                                            left: 0,
                                                            top: 0,
                                                            width: videoSize.width || 250,
                                                            height: videoSize.height || 444,
                                                            type: 'crop'
                                                        }
                                                    ])
                                                }
                                            }} style={{ marginLeft: "10px" }}>保留范围</Button>
                                        )
                                    }
                                </div>
                                <div>
                                    <Checkbox value="superscript">随机角标</Checkbox>
                                </div>
                                <div>
                                    <Checkbox value="name">
                                        花体剧名
                                        {
                                            transformKinds.includes('name') && (
                                                <Button onClick={() => {
                                                    if(rects.findIndex((val) => val.type === 'name') === -1) {
                                                        setRects([
                                                            ...rects,
                                                            {
                                                                id: Date.now(),
                                                                left: 0,
                                                                top: 0,
                                                                width: 250,
                                                                height: 40,
                                                                type: 'name',
                                                                text: productName
                                                            }
                                                        ]);
                                                    }

                                                }} style={{ marginLeft: "10px" }}>标题定位</Button>
                                            )
                                        }
                                    </Checkbox>
                                    {
                                        transformKinds.includes('name') && (
                                            <Input value={productName} onChange={(e) => setProductName(e.target.value)} />   
                                        )
                                    }
                                </div>
                                <div>
                                    <Checkbox value="subtitle">
                                        字幕移位
                                        {
                                            transformKinds.includes('subtitle') && (
                                                <Button onClick={() => {
                                                    if(rects.findIndex((val) => val.type === 'subtitle') === -1) {
                                                        setRects([
                                                            ...rects,
                                                            {
                                                                id: Date.now(),
                                                                left: 0,
                                                                top: videoSize.height * 0.7 || 0,
                                                                width: 250,
                                                                height: 40,
                                                                type: 'subtitle',
                                                                text: productName
                                                            }
                                                        ]);
                                                    }

                                                }} style={{ marginLeft: "10px" }}>字幕定位</Button>
                                            )
                                        }
                                    </Checkbox>
                                    {
                                        transformKinds.includes('subtitle') && (
                                            <Input value={productName} onChange={(e) => setProductName(e.target.value)} />   
                                        )
                                    }
                                </div>
                                <div>
                                    <Checkbox value="flip" disabled={!(taskStatus.cleanVideo && taskStatus.subtitleExtraction)}>镜像翻转</Checkbox>
                                    {
                                        transformKinds.includes('flip') && (
                                            <Slider min={0} max={100} step={5} onChange={(e) => setFlipRate(e)}/>
                                        )
                                    }
                                </div>
                            </Flex>
                        </Checkbox.Group>
                        <div>
                            生成条数: <InputNumber value={taskNum} min={1} max={10} onChange={(e) => setTaskNum(e || 1)}/>
                        </div>
                    </Flex>
                    <Flex vertical={true}>
                        <Radio.Group onChange={(e) => handleuseCleanVideoChange(e.target.value)} value={useCleanVideo}>
                            <Radio value={false}>使用原视频</Radio>
                            <Radio value={true} disabled={!(taskStatus.cleanVideo)}>使用无水印视频</Radio>
                        </Radio.Group>
                        <VideoOverlay
                            rects={rects}
                            type={3}
                            videoUrl={useCleanVideo ?  projectInfo?.cleanVideoUrl : projectInfo?.videoUrl}
                            handleVideoSizeChange={(videoSize) => { setVideoSize(videoSize) }}
                            handleRectsChange={handleRectsChange}
                            handleCleanTaskTypeChange={(type) => setCleanTaskType(type)} />
                    </Flex>
                </Flex>
            </Modal>
            <Modal title="字幕提取" open={isSubtitleModalOpen} onOk={handleSubtitleOk} onCancel={handleSubtitleCancel}>
                <VideoOverlay
                    rects={rects}
                    type={0}
                    videoUrl={projectInfo?.videoUrl || ""}
                    handleVideoSizeChange={(videoSize) => { setVideoSize(videoSize) }}
                    handleRectsChange={handleRectsChange}
                />
            </Modal>
            <Modal title="字幕擦除" open={isCleanVideoModalOpen} onOk={handleCleanVideoOk} onCancel={handleCleanVideoCancel}>
                <VideoOverlay
                    rects={rects}
                    type={1}
                    videoUrl={projectInfo?.cleanVideoUrl || projectInfo?.videoUrl || ""}
                    handleVideoSizeChange={(videoSize) => { setVideoSize(videoSize) }}
                    handleRectsChange={handleRectsChange}
                    handleCleanTaskTypeChange={(type) => setCleanTaskType(type)} />
            </Modal>
        </div>
    )
}