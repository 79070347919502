import React from "react";
import { Button, Upload } from "antd";
import { UploadOutlined } from '@ant-design/icons';
import { v4 as uuidV4 } from "uuid";

function getFileType(filename: string): string | null {
    if (typeof filename !== 'string' || filename.length === 0) {
        return null;
    }
    const extension = filename.split('.').pop();
    return extension ? extension.toLowerCase() : null;
}
const AliyunOSSUpload = ({ ossClient, onFileChange } : { ossClient: any, onFileChange: (type: string, file: any) => void }) => {
    // 上传文件前判断单个文件是否超过500mb，如果超过不允许上传
    function beforeUploadFile(file: { size: number; }, fileList: any) {
        console.log('file=', file)
        const isLt500M = file.size / 1024 / 1024 > 500;
        if (isLt500M) {
            return false
        }
        return true; // 不调用默认的上传方法
    }

    // 上传文件状态回调
    function onUploadFileChange(info: any) {
        console.log('info=', info)
        onFileChange(info.file.status, info.file)
    }

    // 自定义上传
    function uploadFileToOss(options: any) {
        console.log('options=', options)
        const { file, onProgress, onSuccess, onError } = options

        return new Promise(async (resolve, reject) => {
            const fileType = getFileType(file.name)
            const filePath = `/video/${uuidV4()}.${fileType}`
            let result:any = null;
            // 判断文件是否超过100mb，如果超过则使用分片上传，否则使用简单上传
            if (file.size / 1024 / 1024 > 100) {
                console.log('分片上传')
                // 分片上传，默认分片1Mb，如果想更改，请参考：https://help.aliyun.com/document_detail/383952.html
                result = await ossClient.multipartUpload(filePath, file, {
                    progress: async (percent: number) => {
                        onProgress({ percent: percent * 100 })
                    },
                }).catch((error: any) => {
                    onError(error)
                    reject(error)
                })
            } else {
                console.log('简单上传')
                // 简单上传
                console.log(ossClient);
                result = await ossClient.put(filePath, file, {
                    progress: async (percent: number) => {
                        onProgress({ percent: percent * 100 })
                    },
                }).catch((error: any) => {
                    onError(error)
                    reject(error)
                })
            }
            await uploadFileCallback(result, {
                file,
                filePath,
                fileType
            }, (res: any) => {
                onSuccess(res)
                resolve(result)
            })
        })
    }

    async function uploadFileCallback(result: { res: { requestUrls: string[]; status: any; }; name: any; }, data: { file: any; filePath: string; fileType: any; }, callback: { (res: any): void; (arg0: { status: any; name: any; url: any; }): void; }) {
        const ossPath = result.res.requestUrls[0].split('?')[0]
        const url = ossPath.replace(/^http:\/\//i, 'https://')
        const newFileRes = {
            status: result?.res?.status,
            name: result?.name,
            url: url,
        }
        callback(newFileRes)
    }

    return (
        <Upload
            beforeUpload={beforeUploadFile}
            customRequest={uploadFileToOss}
            showUploadList={true}
            withCredentials={true}
            onChange={onUploadFileChange}
            maxCount={1}
        >
            <Button icon={<UploadOutlined />}>上传素材</Button>
        </Upload>
    );
};

export default AliyunOSSUpload