import { get, post } from "./base.js";

export function getProject(params) {
    return get("/project", params);
}

export function getProjectList(params) {
    return get("/project/list", params);
}

export function postProject(body) {
    return post("/project", body);
}

export function postProjectTask(body) {
    return post("/task/project", body);
}