import React, { useRef } from 'react';
import { LaptopOutlined, NotificationOutlined, UserOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Breadcrumb, Layout, Menu, theme } from 'antd';
import { Routes, Route, HashRouter as Router, useLocation } from 'react-router-dom';
import "./App.css";
import SketchList from './modules/SketchList';
import SketchDetail from './modules/SketchDetail';
import ProjectDetail from './modules/ProjectDetail';
import ProjectTask from './modules/ProjectTask';
import ProductTask from './modules/ProductTask';

const { Header, Content, Sider } = Layout;

const items1: MenuProps['items'] = ['1', '2', '3'].map((key) => ({
  key,
  label: `nav ${key}`,
}));

const items2: MenuProps['items'] = [UserOutlined, LaptopOutlined, NotificationOutlined].map(
  (icon, index) => {
    const key = String(index + 1);

    return {
      key: `sub${key}`,
      icon: React.createElement(icon),
      label: `subnav ${key}`,

      children: new Array(4).fill(null).map((_, j) => {
        const subKey = index * 4 + j + 1;
        return {
          key: subKey,
          label: `option${subKey}`,
        };
      }),
    };
  },
);

function BreadcrumbComponent() {
  // useLocation 必须在 Router 组件内部使用
  const location = useLocation();
  // const ref = useRef({ items: [''] });
  // ref.current.items.push(location.pathname);
  // console.log(ref.current.items);
  // console.log(location);
  // 你的面包屑逻辑...
  return (
    <div style={{
      marginBottom: "20px"
    }}>
    <Breadcrumb>
      <Breadcrumb.Item>首页</Breadcrumb.Item>
      <Breadcrumb.Item>
        {location.pathname === '/' ? 'Home' : 'Other Page'}
      </Breadcrumb.Item>
    </Breadcrumb>
    </div>
  );
}

const App: React.FC = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  return (
    <Layout>
      <Layout>
        <Layout style={{ padding: '0 24px 24px' }}>
          <Content
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280,
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
            }}
          >
              <Router>
                <BreadcrumbComponent />
                <Routes>
                  <Route path="/" Component={SketchList}></Route>
                  <Route path="/sketch/:id" Component={SketchDetail}></Route>
                  <Route path="/project/:id" Component={ProjectDetail}></Route>
                  <Route path="/project/task" Component={ProjectTask}></Route>
                  <Route path="/product/task" Component={ProductTask}></Route>
                </Routes>
              </Router>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default App;