import React, { useEffect, useMemo, useState } from 'react';
import { Button, Flex, Modal, Table, Input, Radio } from 'antd';
import AliyunOSSUpload from '../components/AliOssUpload';
import { refreshToken } from '../sdk/sts';
import { getProjectList, postProject } from '../axios/index.js';

import type { TableColumnsType } from 'antd';
import { NavLink, useParams } from 'react-router-dom';


interface Project {
    createdAt: number;
    name: string;
    id: string;
}

const columns: TableColumnsType<Project> = [
    {
        key: 'name',
        title: 'Name',
        dataIndex: 'name',
    },
    {
        key: 'options',
        title: '操作',
        dataIndex: 'options',
        render: (_, val) => {
            return (<NavLink to={`/project/${val.id}`} key={val.id}>查看</NavLink>)
        }
    },
];

export default function SketchDetail() {
    const params = useParams();
    const sketchId = useMemo(() => params.id, [params]);
    const [projectList, setProjectList] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [projectName, setProjectName] = useState("");
    const [ossClient, setOssClient] = useState<any>(null);
    const [videoUrl, setVideoUrl] = useState("");
    const [type, setType] = useState("")

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
        postProject({
            videoUrl,
            type,
            name: projectName,
            sketchId
        }).then(({data}) => {
            getProjectListData();
        })
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleFileChange = (type: string, file: any) => {
        console.log(type, file);
        if(type === 'done') {
            setVideoUrl(file.response.url)
        } else {
            setVideoUrl("");
        }
    }

    function getProjectListData() {
        getProjectList({ sketchId }).then(({data}) => {
            if(data.status === "error") {
            } else {
                setProjectList(data.result);
            }
        })
    }

    useEffect(() => {
        refreshToken().then((ossClient) => {
            setOssClient(ossClient);
        });
        getProjectListData();
    }, [])
    return (
        <div>
            <Flex gap="middle" vertical>
                <Flex gap="middle">
                    <Button onClick={showModal}>添加新项目</Button>
                    <Button>删除</Button>
                </Flex>
                <Table columns={columns} dataSource={projectList} />
            </Flex>
            <Modal title="新项目" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <Flex vertical={true} gap={10}>
                    <Input onChange={(e) => { setProjectName(e.target.value) }} placeholder='项目名称'/>
                    {
                        ossClient && (
                            <AliyunOSSUpload ossClient={ossClient} onFileChange={handleFileChange}/>
                        )
                    }
                    <Radio.Group onChange={(e) => setType(e.target.value)} value={type}>
                        <Radio value={0}>纯剧情</Radio>
                        <Radio value={1}>有解说</Radio>
                    </Radio.Group>
                </Flex>
            </Modal>
        </div>
    )
}