import { get, post } from "./base.js";

/**
 * @param {any} params
 */
export function getLineList(params) {
    return get("/line/list", params)
}

/**
 * @param {any} body
 */
export function postLine(body) {
    return post("/line", body)
}

/**
 * @param {any} body
 */
export function postLineRole(body) {
    return post("/line/role", body)
}