import React, { useEffect, useState } from 'react';
import type { TableProps } from 'antd';
import { Form, Input, InputNumber, Popconfirm, Table, Typography } from 'antd';
import { Line } from '../interface';
import { title } from 'process';


interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: 'number' | 'text';
  record: Line;
  index: number;
}

const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

interface Props {
    lines: Line[],
    onLineChange: (line: Line) => void
}

export default function EditableTable(props: Props) {
  const { lines, onLineChange } = props;
  const [form] = Form.useForm();
  const [data, setData] = useState<Line[]>(lines);
  const [editingKey, setEditingKey] = useState<string>("");
  const isRewrite = lines.findIndex((line) => line.rewriteText) > -1;

  const isEditing = (record: Line) => record.id === editingKey;

  const edit = (record: Partial<Line> & { id: React.Key }) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.id);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (key: React.Key) => {
    try {
      const row = (await form.validateFields()) as Line;

      const newData = [...data];
      const index = newData.findIndex((item) => key === item.id);
      if (index > -1) {
        const item = newData[index];
        const newItem: Line = {
            ...item,
            ...row,
        }
        onLineChange(newItem);
        newData.splice(index, 1, newItem);
        setData(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const columns = [
    {
        key: 'id',
        title: "ID",
        dataIndex: 'id',
        editable: false,
        render: (val: string) => {
            return <div style={{ width: "40px", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>{val}</div>
        }
    },
    {
        key: 'text',
        title: '台词',
        dataIndex: 'text',
        editable: true
    },
    {
        key: 'startTime',
        title: '开始时间',
        dataIndex: 'startTime',
        editable: true
    },
    {
        key: 'endTime',
        title: '结束时间',
        dataIndex: 'endTime',
        editable: true
    },
    {
        key: 'role',
        title: '角色',
        dataIndex: 'role',
        editable: true
    },
    {
      title: 'operation',
      dataIndex: 'operation',
      render: (_: any, record: Line) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link onClick={() => save(record.id)} style={{ marginInlineEnd: 8 }}>
              保存
            </Typography.Link>
            <Typography.Link onClick={cancel} style={{ marginInlineEnd: 8 }}>
              取消
            </Typography.Link>
          </span>
        ) : (
          <Typography.Link disabled={editingKey !== ""} onClick={() => edit(record)}>
            编辑
          </Typography.Link>
        );
      },
    },
  ];
  if(isRewrite) {
    columns.splice(2, 0, {
        key: 'rewriteText',
        title: '改写台词',
        dataIndex: 'rewriteText',
        editable: true
    })
  }

  const mergedColumns: TableProps<Line>['columns'] = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: Line) => ({
        record,
        inputType: ["startTime", "endTime"].includes(col.dataIndex) ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  useEffect(() => {
    setData(lines);
  }, [lines])

  return (
    <Form form={form} component={false}>
      <Table<Line>
        components={{
          body: { cell: EditableCell },
        }}
        bordered
        dataSource={data}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={{ onChange: cancel }}
      />
    </Form>
  );
};
