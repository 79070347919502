import OSS from "ali-oss";
import { getSTSToken } from "../axios/index.js";

let client: OSS | undefined;
export function refreshToken() {
  return new Promise((resolve, reject) => {
    getSTSToken({}).then(({ data }) => {
      const { result } = data;
      if(data.status === 'error') {
        resolve(null);
      } else {
        client = new OSS({
          // yourRegion填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
          region: 'oss-cn-shanghai',
          // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
          accessKeyId: result.accessKeyId,
          accessKeySecret: result.accessKeySecret,
          // 从STS服务获取的安全令牌（SecurityToken）。
          stsToken: result.securityToken,
          refreshSTSToken: async () => {
            // 向您搭建的STS服务获取临时访问凭证。
            const { data } = await getSTSToken({});
            return {
              accessKeyId: data.result.accessKeyId,
              accessKeySecret: data.result.accessKeySecret,
              stsToken: data.result.securityToken
            }
          },
          // 刷新临时访问凭证的时间间隔，单位为毫秒。
          refreshSTSTokenInterval: 300000,
          // 填写Bucket名称。
          bucket: 'playlet-li'
        });
        resolve(client);
      }
    })
  });
};
refreshToken();
export function getSTSClient() {
  return client;
}