import axios from 'axios';

const instance = axios.create({
  // baseURL: 'http://8.218.59.113:3000', // 设置基本的 URL
  // baseURL: 'http://localhost:3001/',
  timeout: 10 * 1000, // 设置超时时间（单位：毫秒）
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json', // 设置请求头
  },
});
instance.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token');
  config.headers.Authorization = `Bearer ${token}`;
  return config;
},
  function (error) {
    // Do something with request error
    return Promise.reject(error);
});
instance.interceptors.response.use(function (response) {
//   const { data } = response;
//   if (data.status === "error") {
//     if (data.errorCode === 1001 && window.location.hash !== "#/login") {
//       localStorage.setItem('token', "");
//       window.location.href = "#/login";
//     }
//   } else {
//     // @ts-ignore
//     response.headers.getAuthorization() && localStorage.setItem('token', response.headers.getAuthorization());
//   }
  return response;
}, function (error) {
  // 超出 2xx 范围的状态码都会触发该函数。
  // 对响应错误做点什么
  // return Promise.reject(error);
  if (error.request.status === 404) {
    return {
      data: {
        status: "error",
        errorMessage: "未知接口",
      }
    };
  }
  return {
    data: {
      status: "error",
      errorMessage: "网路错误",
    }
  };
});

/**
 * @param {string} url
 * @param {any} params
 */
export function get(url, params = {}) {
  return instance.get(url, { params });
}

/**
 * @param {string} url
 * @param {any} data
 */
export function post(url, data) {
  return instance.post(url, data);
}

/**
 * @param {any} url
 * @param {any} fd
 */
export function postForm(url, fd) {
  return instance.post(url, fd, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}