import React, { useEffect, useMemo, useState } from "react";
import { Button, Flex, Input, Modal } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import { getLineList, postLine, postLineRole, postProductTask } from "../axios";
import EditableTable from "../components/EditTable";
import { Line } from "../interface";

interface NickNameProps {
    index: number;
    nickname: string;
    handleNickNameChange: (nickname: string, index: number) => void;
}
function NickName(props: NickNameProps) {
    const { nickname, index, handleNickNameChange } = props;
    const [value, setValue] = useState(nickname);
    const [disableInput, setDisableInput] = useState(true);
    function handleSave() {
        handleNickNameChange(value, index);
    }
    function handleCancel() {
        setDisableInput(true);
        setValue(nickname);
    }
    return (
        <Flex justify={"center"}>
            <Flex style={{ width: "300px" }}>
                <Input disabled={disableInput} value={value} onChange={(e) => setValue(e.target.value)}/>
                {
                    disableInput ? (
                        <Button onClick={() => { setDisableInput(false) }}>编辑</Button>
                    ) : (
                        <>
                            <Button onClick={() => { handleSave() }}>保存</Button>
                            <Button onClick={() => { handleCancel() }}>取消</Button>
                        </>
                    )
                }
            </Flex>
        </Flex>
    )
}

export default function ProjectTask() {
    const location = useLocation();
    const project = useMemo(() => location.state.project || {}, [location]);
    const projectId = useMemo(() => location.state.project?.id || "", [location]);
    const videoUrl = useMemo(() => location.state.project?.videoUrl || "", [location]);
    const [nickNameModalOpen, setNickNameModalOpen] = useState(false);
    const [roles, setRoles] = useState<string[]>([]);
    const [lineList, setLineList] = useState<Line[]>([]);
    useEffect(() => {
        getLineList({ projectId: project.id }).then(({data}) => {
            setLineList(data.result);
            const _roles = new Set<string>();
            for(const line of data.result) {
                _roles.add(line.role);
            };
            setRoles([..._roles]);
        })
    }, []);
    async function handleLineChange(line: Line) {
        postLine(line).then(({data}) => {
            console.log(data);
        })
    }
    async function handleRoleNickNameChange(newName: string, index: number) {
        const _roles = [...roles];
        const oldName = _roles[index];
        _roles[index] = newName;
        setRoles(_roles);
        setLineList(lineList.map((val) => {
            if(val.role === oldName) {
                val.role = newName;
            };
            return val
        }))
        await postLineRole({ projectId, role: oldName, newRole: newName });
    }

    return (
        <div>
            <div>
                <Button onClick={() => setNickNameModalOpen(true)}>昵称修改</Button>
            </div>
            <Flex justify={"space-between"}>
                <EditableTable lines={lineList} onLineChange={handleLineChange}/>
                {
                    videoUrl && (
                        <div>
                            <video controls style={{ width: "300px" }}>
                                <source src={videoUrl}/>
                            </video>
                        </div>
                    )
                }
            </Flex>
            <Modal open={nickNameModalOpen} onOk={() => setNickNameModalOpen(false)} onCancel={() => setNickNameModalOpen(false)}>
                {
                    roles.map((val, index) => {
                       return  <NickName nickname={val} index={index} handleNickNameChange={handleRoleNickNameChange}/>
                    })
                }
            </Modal>
        </div>
    )
}