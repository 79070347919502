import React, { useState, useRef, useEffect } from 'react';
import { Button, Flex, Radio } from 'antd';
import { Rect, VideoSize } from '../interface/index';
import './VideoOverlay.css';

interface Props {
  videoUrl?: string,
  type: number,
  rects?: Rect[],
  handleVideoSizeChange: (videoSize: VideoSize) => void,
  handleRectsChange: (rects: Rect[]) => void,
  handleCleanTaskTypeChange?: (type: number) => void,
}
const VideoOverlay = (props: Props) => {
  const { videoUrl, type, rects = [], handleVideoSizeChange, handleRectsChange, handleCleanTaskTypeChange } = props;
  const videoRef = useRef<any>(null);
  const [videoWidth, setVideoWidth] = useState(0);
  const [videoHeight, setVideoHeight] = useState(0);
  const [cleanTaskType, setCleanTaskType] = useState(0);
  // Handler to add a new rectangle
  const addRectangle = () => {

    if (type === 0 && rects.length > 3) {
      return;
    }
    if (type === 1 && rects.length > 1) {
      return;
    }
    if (type === 2 && rects.length > 1) {
      return;
    }
    handleRectsChange([...rects, { id: Date.now(), top: 50, left: 50, width: 100, height: 100 }])
  };

  const handleVideoCanPlay = () => {
    handleVideoSizeChange({ width: videoRef.current.clientWidth, height: videoRef.current.clientHeight });
    setVideoWidth(videoRef.current.clientWidth);
    setVideoHeight(videoRef.current.clientHeight);
  }

  // Handler for drag
  const handleDrag = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
    e.preventDefault();
    const offsetX = e.clientX - rects[index].left;
    const offsetY = e.clientY - rects[index].top;

    const handleMouseMove = (moveEvent: { clientX: number; clientY: number; }) => {
      const updatedRectangles = [...rects];
      const { width, height } = updatedRectangles[index];
      let left = moveEvent.clientX - offsetX;
      if (left < 0) left = 0;
      if (left + width >= videoWidth) left = videoWidth - width;
      let top = moveEvent.clientY - offsetY;
      if (top < 0) top = 0;
      if (top + height >= videoHeight) top = videoHeight - height;
      updatedRectangles[index] = {
        ...updatedRectangles[index],
        left,
        top,
      };
      handleRectsChange(updatedRectangles)
    };

    const handleMouseUp = () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  // Handler for resize
  const handleResize = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
    e.preventDefault();
    const initialWidth = rects[index].width;
    const initialHeight = rects[index].height;
    const startX = e.clientX;
    const startY = e.clientY;

    const handleMouseMove = (moveEvent: { clientX: number; clientY: number; }) => {
      const updatedRectangles = [...rects];
      let width = initialWidth + (moveEvent.clientX - startX);
      const { left, top } = updatedRectangles[index];
      if (left + width > videoWidth) width = videoWidth - left;
      let height = initialHeight + (moveEvent.clientY - startY);
      if (top + height > videoHeight) height = videoHeight - top;
      updatedRectangles[index] = {
        ...updatedRectangles[index],
        width,
        height,
      };
      handleRectsChange(updatedRectangles)
    };

    const handleMouseUp = () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const onCleanTaskTypeChange = (type: number) => {
    setCleanTaskType(type);
    handleCleanTaskTypeChange?.(type);
  }

  return (
    <div>
      <Flex justify={"space-between"}>
        <div>
          {
            type === 0 && <Button onClick={addRectangle}>添加字幕框</Button>
          }
          {
            type === 1 && (
              <>
                <Radio.Group onChange={(e) => onCleanTaskTypeChange(e.target.value)} value={cleanTaskType}>
                  <Radio value={0}>去字幕</Radio>
                  <Radio value={1}>去阴影字幕</Radio>
                  <Radio value={2}>去水印</Radio>
                </Radio.Group>
                <Button onClick={addRectangle}>添加擦除框</Button>
              </>
          )
          }
          
        </div>
        <div>
          <div className="video-container">
            {
              videoUrl && (
                <video id="video" controls width="250" ref={videoRef} onCanPlayThrough={handleVideoCanPlay} src={videoUrl}>
                </video>
              )
            }
            {rects.map((rect, index) => (
              <div
                className={`draggable ${rect.type || 'normal'}`}
                key={rect.id}
                style={{
                  top: `${rect.top}px`,
                  left: `${rect.left}px`,
                  width: `${rect.width}px`,
                  height: `${rect.height}px`,
                  boxSizing: "border-box"
                }}>
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    textAlign: "center",
                  }}
                  onMouseDown={(e) => handleDrag(e, index)}
                >
                  { rect.text }
                </div>
                <div
                  className="resize-handle"
                  onMouseDown={(e) => handleResize(e, index)}
                ></div>
              </div>
            ))}
          </div>
        </div>
      </Flex>
    </div>
  );
};

export default VideoOverlay;