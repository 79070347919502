import { get, post } from "./base.js";

export function getSketchList(params) {
    return get("/sketch/list", params);
}

export function getSketch(params) {
    return get("/sketch", params);
}

export function postSketch(body) {
    return post("/sketch", body);
}